import React from "react";
import { motion } from "framer-motion";

const AnimatedPage = () => {
  return (
    <motion.div
      className="page-transition-overlay"
      initial={{ height: "100vh" }}
      animate={{ height: "0vh" }}
      exit={{ height: "100vh" }}
      transition={{ duration: 1, ease: "easeInOut" }}>
      {/* <motion.div
        className="black-overlay"
        initial={{ height: "100vh"}}
        animate={{ height: "0vh" }}
        exit={{ height: "100vh"}}
        transition={{ duration: 1.6, ease: "easeInOut" }}></motion.div> */}
    </motion.div>
  );
};

export default AnimatedPage;
