import React from "react";
import { NavLink } from "react-router-dom";
import { Container } from "react-bootstrap";

const Navigation = () => {
  return (
    <nav>
      <Container>
        <div className="nav-columns">
          <div className="nav-column">
            <ul className="nav-links">
              <li>
                <NavLink
                  to="/"
                  className={(navData) => (navData.isActive ? "active" : "")}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/project"
                  className={(navData) => (navData.isActive ? "active" : "")}>
                  Project
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/partners"
                  className={(navData) => (navData.isActive ? "active" : "")}>
                  Partners
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/news"
                  className={(navData) => (navData.isActive ? "active" : "")}>
                  News
                </NavLink>
              </li>
              <li>
                <a
                  href="https://app.comsolve.pt/"
                  target="_blank"
                  rel="noreferrer">
                  Platform
                </a>
              </li>
              <li>
                <a
                  href="https://api.comsolve.pt/external/docs"
                  target="_blank"
                  rel="noreferrer">
                  Documentation
                </a>
              </li>
              <li>
                <a
                  href="https://forms.gle/hTV7mrshwMn8ZgTd7"
                  target="_blank"
                  rel="noreferrer">
                  Apply to Join
                </a>
              </li>
            </ul>
          </div>
          <div className="nav-column">
            <div className="nav-infos">
              <div className="nav-info">
                <p>
                  <span className="text-bold text-uppercase">Digitalmente</span>
                  <br />
                  telefone: +351 234 081 249
                  <br />
                  email: comercial@digitalmente.net
                </p>
                <p className="mt-4 mb-2">
                  Rua Padre Donaciano Abreu Freire nº43 RC/A
                  <br />
                  3860 - Estarreja
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </nav>
  );
};

export default Navigation;
