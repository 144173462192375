import React, { useEffect, useState } from "react";
import gsap from "gsap";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Banner from "../components/banner";
import imgPanel from "../assets/img_panel.jpg";
import circularComsolve from "../assets/circular_comsolve.svg";
import PartnersComponent from "../components/partnersComponent";
import IntroOverlay from "../components/introOverlay";
import { motion } from "framer-motion";

const tl = gsap.timeline();

const homeAnimation = (completeAnimation) => {
  tl.from(".line span", {
    y: 160,
    ease: "power4.out",
    delay: 0.5,
    duration: 1.8,
    skewY: 8,
    stagger: {
      amount: 0.3,
    },
  })
    .to(".overlay-top", {
      duration: 1.6,
      height: "40vh",
      ease: "expo.inOut",
    })
    .to(".overlay-top", {
      left: "calc(16.4px + 2vw)",
      right: "calc(16.4px + 2vw)",
    })
    .to(".overlay-top", {
      opacity: 0,
    })
    .to(".intro-overlay", {
      css: { display: "none" },
      onComplete: completeAnimation,
    });
  /* .to("body, html", {
      css: { overflow: "auto" },
    }) */
};

const Home = () => {
  const [animationComplete, setAnimationComplete] = useState(false);

  const completeAnimation = () => {
    setAnimationComplete(true);
  };

  useEffect(() => {
    // On load timeline
    homeAnimation(completeAnimation);
  }, []);

  return (
    <>
      <motion.div
        className="page-transition-overlay"
        initial={{ height: "0" }}
        exit={{ height: "100vh" }}
        transition={{ duration: 1.6, ease: "easeInOut" }}></motion.div>
      {animationComplete === false ? <IntroOverlay /> : ""}
      <Banner />

      <section className="home-body">
        <div className="home-project">
          <Container>
            <Row>
              <Col xl={5} className="pb-5">
                <span className="title-upper">Project</span>
              </Col>
              <Col xl={7}>
                <p className="medium-text">
                  COMSOLVE aims to develop management solutions for renewable
                  energy communities (CER) with the integration of electric
                  vehicles and energy storage systems based on second life
                  batteries.
                </p>
                <p className="text-md-end mt-5">
                  <NavLink to="/project" className="circle-link">
                    <i className="circle"></i>
                    <span className="link">see more &nbsp; —</span>
                  </NavLink>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="home-features">
          <Container>
            <Row>
              <Col lg={5} className="panel-composition pb-5">
                <img src={imgPanel} className="img-panel" alt="Panel" />
                <img
                  src={circularComsolve}
                  className="circular-comsolve"
                  alt="Panel"
                  width={120}
                />
              </Col>
              <Col lg={{ span: 6, offset: 1 }} className="features-list">
                <div className="features-item">
                  <p className="subtitle">Decentralized</p>
                  <p className="normal-text">
                    production of electricity from photovoltaic panels
                  </p>
                </div>
                <hr />
                <div className="features-item">
                  <p className="subtitle">Sharing</p>
                  <p className="normal-text">
                    of energy produced by members of the community
                  </p>
                </div>
                <hr />
                <div className="features-item">
                  <p className="subtitle">Prosumers</p>
                  <p className="normal-text">
                    are citizens and entities simultaneously producers and
                    consumers of electricity
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <PartnersComponent />
      </section>
    </>
  );
};

export default Home;
