import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../assets/logo.svg";
import financiers from "../assets/financiers.png";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col lg={4} className="footer-sections pb-5 pb-lg-0">
            <img src={logo} width={130} alt="COMSOLVE" />
            <span className="copyright d-none d-lg-block">© 2022 COMSOLVE</span>
          </Col>
          <Col lg={4} className="footer-sections pb-5 pb-lg-0">
            <ul className="links">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/project">Project</NavLink>
              </li>
              <li>
                <NavLink to="/partners">Partners</NavLink>
              </li>
              <li>
                <NavLink to="/news">News</NavLink>
              </li>
              <li>
                <a
                  href="https://app.comsolve.pt/"
                  target="_blank"
                  rel="noreferrer">
                  Platform
                </a>
              </li>
              <li>
                <a
                  href="https://api.comsolve.pt/external/docs"
                  target="_blank"
                  rel="noreferrer">
                  Documentation
                </a>
              </li>
              <li>
                <a
                  href="https://forms.gle/hTV7mrshwMn8ZgTd7"
                  target="_blank"
                  rel="noreferrer">
                  Apply to Join
                </a>
              </li>
            </ul>
            <img src={financiers} className="financiers" alt="portugal2020" />
          </Col>
          <Col lg={4} className="footer-sections">
            <p>
              <span className="text-bold text-uppercase">Digitalmente</span>
              <br />
              telefone: +351 234 081 249
              <br />
              email: comercial@digitalmente.net
            </p>
            <p className="mt-4">
              Rua Padre Donaciano Abreu Freire nº43 RC/A
              <br />
              3860 - Estarreja
            </p>
            <span className="copyright d-sm-block d-lg-none pt-5">
              © 2022 COMSOLVE
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
