import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AnimatedPage from "../components/animatedPage";
import PartnersComponent from "../components/partnersComponent";

const Partners = () => {
  return (
    <>
      <AnimatedPage />
      <section className="main pb-0">
        <Container>
          <Row>
            <Col className="text-center">
              <span className="title">Partners</span>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={10}>
              <div className="features mb-0">
                <div className="feature-item">
                  <p className="medium-text">
                    Digitalmente - Novas Tecnologias de Comunicação Lda
                  </p>
                  <p className="description">
                    With more than a decade of existence, Digitalmente is a
                    technological company specialized in the design and
                    development of software and hardware solutions, aiming at
                    new applications and new business processes.
                  </p>
                </div>
                <div className="feature-item">
                  <p className="medium-text">
                    Coopérnico - Cooperativa de Desenvolvimento Sustentável CRL
                  </p>
                  <p className="description">
                    Coopérnico is a renewable energy cooperative that combines
                    its social nature with support for solidarity, educational
                    or environmental protection projects.
                  </p>
                </div>
                <div className="feature-item">
                  <p className="medium-text">Instituto de Telecomunicações</p>
                  <p className="description">
                    Instituto de Telecomunicações is a private, not-for-profit
                    organization, of public interest, a partnership of nine
                    institutions with research and development in the field of
                    Telecommunications.
                  </p>
                </div>
                <div className="feature-item">
                  <p className="medium-text">
                    Magnum Cap - Electrical Power Solutions Lda
                  </p>
                  <p className="description">
                    Magnum Cap is a leader company on creating and developing
                    infrastructure to receive electric vehicles, focused on
                    developing charging solutions, energy storage capacity and
                    low impact of vehicle/grid interfaces, for a for a reliable,
                    easy to use and secure day to day use of battery based
                    electric vehicles, by taking advantage of renewable energies
                    and preserving electric grid.
                  </p>
                </div>
                <div className="feature-item">
                  <p className="medium-text">
                    Junta de Freguesia de São Salvador
                  </p>
                  <p className="description"></p>
                </div>
                <div className="feature-item">
                  <p className="medium-text">Câmara Municipal de Ílhavo</p>
                  <p className="description"></p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <PartnersComponent />
      </section>
    </>
  );
};

export default Partners;
