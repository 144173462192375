import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import LinesEllipsis from "react-lines-ellipsis";
import AnimatedPage from "../components/animatedPage";

const News = () => {
  const newsArr = [
    {
      title: 'Cooperativa de energias renováveis promove sessão de esclarecimentos para ilhavenses',
      description:
        "A primeira cooperativa de energias renováveis de Portugal, Coopérnico, vai promover uma sessão de esclarecimentos para os munícipes ilhavenses hoje, às 18:30 horas, via Zoom, sobre como investir na construção de uma comunidade de energia renovável em Ílhavo.",
      image:
        "https://oilhavense.com/wp-content/uploads/2023/10/391743464_711035404393107_1775816706991714475_n-696x696.jpg",
      url: "https://oilhavense.com/2023/10/18/cooperativa-de-energias-renovaveis-promove-sessao-de-esclarecimentos-para-ilhavenses/",
      from: "O Ilhavense",
    },
    {
      title: 'Coopérnico levou ao município de Ílhavo o primeiro investimento de autoconsumo coletivo para a comunidade',
      description:
        "A Coopérnico, cooperativa de energias renováveis, atingiu recentemente um marco significativo com mais de dois milhões de euros de investimento em produção renovável, impulsionados pelos seus cooperantes. Agora, em parceria com a câmara municipal de Ílhavo, a Coopérnico lança um projeto revolucionário no âmbito do programa COMSOLVE, que visa a instalação de um sistema fotovoltaico para partilha de energia. Este sistema será disponibilizado para investimento pelos residentes de Ílhavo, de modo a permitir que estes se tornem proprietários da produção de energia renovável no seu concelho.",
      image:
        "https://edificioseenergia.pt/wp-content/uploads/2020/08/2109autoconsumo.png",
      url: "https://edificioseenergia.pt/empresas/1810-coopernico-levou-ao-municipio-de-ilhavo-o-primeiro-investimento-de-autoconsumo-coletivo-para-a-comunidade/",
      from: "Edifícios e Energia",
    },
    {
      title: 'Cooperativa de energias renováveis convida habitantes de Ílhavo a serem proprietários',
      description:
        "A Coopérnico, a primeira cooperativa de energias renováveis de Portugal, está a convidar os habitantes de Ílhavo a tornarem-se proprietários da energia renovável gerada no concelho.",
      image:
        "https://pmemagazine.sapo.pt/wp-content/uploads/2023/10/Energia-Solar-660x330.jpg",
      url: "https://pmemagazine.sapo.pt/cooperativa-de-energias-renovaveis-convida-habitantes-de-ilhavo-a-serem-proprietarios/",
      from: "PME Magazine",
    },
    {
      title: 'Ílhavo: Cooperativa de energias renováveis procura investidores locais.',
      description:
        "A primeira cooperativa de energias renováveis de Portugal e a Câmara de Ílhavo juntas no arranque do projeto de partilha de energia renovável. Com mais de 2 milhões de euros já alcançados, a Coopérnico oferece aos munícipes de Ílhavo a oportunidade de se tornarem proprietários de energia renovável gerada no seu próprio concelho.",
      image:
        "https://www.terranova.pt/sites/default/files/styles/node-detail/public/field/image/paineis_solares_1_0.jpg?itok=t27wMfe1",
      url: "https://www.terranova.pt/noticia/economia/ilhavo-cooperativa-de-energias-renovaveis-procura-investidores-locais",
      from: "Terra Nova",
    },
    {
      title: 'Comunidade de energia local com autoconsumo de renováveis dinamizada em Ílhavo',
      description:
        "A Câmara de Ílhavo e a Coopérnico, cooperativa de energias renováveis, anunciaram que vão dinamizar a criação de uma comunidade de energia local, no âmbito do projeto “COMSOLVE”.",
      image:
        "https://greensavers.sapo.pt/wp-content/uploads/2022/09/paineis-solares.jpg",
      url: "https://greensavers.sapo.pt/comunidade-de-energia-local-com-autoconsumo-de-renovaveis-dinamizada-em-ilhavo/",
      from: "Green Savers",
    },
    {
      title: 'Município de Ílhavo aposta em produção de energia verde',
      description:
        "O Município de Ílhavo vai instalar, no Edifício da Câmara Municipal de Ílhavo, uma Unidade de Produção para Autoconsumo Coletivo, a primeira de âmbito municipal aprovada pela Direção-Geral de Energia e Geologia, que será capaz de gerar eletricidade limpa e renovável.",
      image:
        "https://www.aveiromag.pt/wp-content/uploads/2023/07/Camara-de-Ilhavo-copia-1024x687.jpeg",
      url: "https://www.aveiromag.pt/2023/07/09/municipio-de-ilhavo-aposta-em-producao-de-energia-verde/",
      from: "Aveiro Mag",
    },
    {
      title: 'Câmara de Ílhavo instala painéis fotovoltaicos nos Paços do Concelho',
      description:
        "Câmara de Ílhavo deverá poupar 371 mil euros e reduzir metade do consumo de energia da rede pública com a instalação de painéis fotovoltaicos.",
      image:
        "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQhapNHsDJbmO20jkldkGa5U_PoN2ck8b96AuKangHbeR07xdAoBsT1btNC",
      url: "https://eco.sapo.pt/2023/07/10/camara-de-ilhavo-instala-paineis-fotovoltaicos-nos-pacos-do-concelho/",
      from: "Eco Sapo (Local Online)",
    },
    {
      title: 'Ílhavo: Unidade de Produção para Autoconsumo Coletivo nos Paços de Concelho',
      description:
        "O Município de Ílhavo vai instalar, no Edifício da Câmara Municipal de Ílhavo, uma Unidade de Produção para Autoconsumo Coletivo, a primeira de âmbito municipal aprovada pela Direção-Geral de Energia e Geologia, que será capaz de gerar eletricidade limpa e renovável.",
      image:
        "https://www.noticiasdeaveiro.pt/wp-content/uploads/2018/10/cmi.jpg",
      url: "https://www.noticiasdeaveiro.pt/ilhavo-unidade-de-producao-para-autoconsumo-coletivo-nos-pacos-de-concelho/",
      from: "Notícias de Aveiro",
    },
    {
      title: 'Câmara de Ílhavo instala sistema fotovoltaico que reduz em 56% consumo energético',
      description:
        "A Câmara Municipal de Ílhavo, no distrito de Aveiro, vai instalar no edifício municipal um sistema fotovoltaico que permitirá uma redução anual de cerca de 56% do consumo da rede elétrica pública, foi anunciado este sábado.",
      image:
        "https://static.globalnoticias.pt/dv/image.jpg?brand=DV&type=generate&guid=72115722-3f19-4eb2-b8b4-99c1e71324fe&w=800&h=450&t=20230708144439",
      url: "https://www.dinheirovivo.pt/economia/nacional/camara-de-ilhavo-instala-sistema-fotovoltaico-que-reduz-em-56-consumo-energetico-16660003.html",
      from: "Dinheiro Vivo",
    },
    {
      title: 'II Conferência das CER em Portugal é já amanhã. Coopérnico quer que "Pessoas se cheguem à frente" e criem mais projetos',
      description:
        "É já amanhã a II Conferência das Comunidades de Energia Renovável (CER) em Portugal, organizada pela Coopérnico. O evento, que vai ter lugar no Museu Marítimo de Ílhavo entre as 10 e as 13 horas, vai focar-se no papel das CER na transição energética, no combate à pobreza energética e na flexibilidade do consumo e em medidas para acelerar a implementação deste modelo no país, apresentando também três projectos distintos que revelam diferentes dinâmicas de CER: o COMSOLVE, o POCITYF e o COMMUNITAS. “Queremos que as pessoas se cheguem à frente e consigam implementar estes projectos sem muita burocracia”, defende Alekson Luz, Técnico de Produção da Coopérnico.",
      image:
        "https://edificioseenergia.pt/wp-content/uploads/2021/02/0102energiacomunidade.png",
      url: "https://edificioseenergia.pt/noticias/ii-conferencia-das-cer-em-portugal-e-ja-amanha-coopernico-quer-que-pessoas-se-cheguem-a-frente-e-criem-mais-projectos/",
      from: "Edifícios e Energia",
    },
    {
      title: "II Conferência das Comunidades de Energia Renovável em Portugal decorre em Ílhavo.",
      description:
        "A Coopérnico marca presença na II Conferência das Comunidades de Energia Renovável em Portugal, no âmbito do projeto COMSOLVE. Esta segunda edição acontece no Museu Marítimo de Ílhavo, dia 22 de junho entre as 10:00h e as 13:00h.",
      image:
        "https://www.terranova.pt/sites/default/files/styles/node-detail/public/field/image/306832015_570501834875383_4310009210226568276_n.jpg?itok=L9mRu9Rv",
      url: "https://www.terranova.pt/noticia/sociedade/ii-conferencia-das-comunidades-de-energia-renovavel-em-portugal-decorre-em-ilhavo",
      from: "Terra Nova",
    },
    {
      title: "Prémio cidades e territórios do futuro distinguiu seis projetos pioneiros",
      description:
        "Já são conhecidos os vencedores da segunda edição do Prémio Cidades e Territórios do Futuro, promovido pela APDC - Associação Portuguesa para o Desenvolvimento das Comunicações. Os distinguidos foram anunciados esta terça-feira durante o 32.º Digital Business Congress, da APDC, que decorre durante dois dias - 9 e 10 de Maio - em Lisboa.",
      image:
        "https://smart-cities.pt/wp-content/uploads/2023/05/apdc.jpg",
      url: "https://smart-cities.pt/noticias/premio-cidades-e-territorios-do-futuro-distinguiu-seis-projectos-pioneiros-2023/",
      from: "Smart Cities",
    },
    {
      title: "Da saúde à sustentabilidade, conheça os vencedores do Prémio Cidades e Territórios do Futuro",
      description:
        "Já são conhecidos os vencedores da segunda edição do Prémio Cidades e Territórios do Futuro, que distingue projetos e soluções pioneiras, de base tecnológica, em sete categorias distintas, com capacidade para tornar as cidades e os territórios espaços mais habitáveis, sustentáveis e economicamente viáveis. Os premiados foram anunciados esta terça-feira, no 32.º Digital Business Congress, pela APDC.",
      image:
        "https://ecoonline.s3.amazonaws.com/uploads/2023/05/projeto-vencedor-mobicab-flexivel-mobilidade-de-castelo-branco-transporte-flexivel-relacionamento-com-o-cidadacc83o-e-participaccca7acc83o.jpeg",
      url: "https://eco.sapo.pt/2023/05/09/da-saude-a-sustentabilidade-conheca-os-vencedores-do-premio-cidades-e-territorios-do-futuro/",
      from: "Eco Sapo",
    },
    {
      title: "Coopérnico de olhos postos num futuro sustentável",
      description:
        "A Coopérnico é uma cooperativa portuguesa de energias renováveis e faz parte do projeto COMSOLVE, que se encontra a criar uma comunidade de energia solar, no município de Ílhavo, em Aveiro, com integração de veículos elétricos com o objetivo de apostar na mobilidade e criar os “prosumidores”, i. e., consumidores que produzem a sua própria energia.",
      image:
        "https://pmemagazine.sapo.pt/wp-content/uploads/2023/04/PME28-AMBIENTE-Copernico-660x330.jpg",
      url: "https://pmemagazine.sapo.pt/coopernico-de-olhos-postos-num-futuro-sustentavel/",
      from: "PME Magazine",
    },
    {
      title:
        "TMF organize a workshop in the event “O papel da mobilidade elétrica na transição energética”",
      description:
        "TMF wants to thank the Coopérnico cooperative and the project partners of COMSOLVE for inviting us to organize a workshop about the first steps for creating an electric mobility cooperative in the framework of the event. The event, called “O papel da mobilidade elétrica na transição energética” (The role of the electric mobility in the energetic transition), took place in the beautiful Municipal Library of Orlando Ribeiro in Lisboa.",
      image:
        "https://themobilityfactory.coop/wp-content/uploads/2023/01/WhatsApp-Image-2023-01-12-at-16.24.22-1.jpeg",
      url: "https://themobilityfactory.coop/2023/01/16/tmf_organize_a_workshop_in_the_event_o-papel-da-mobilidade-eletrica-na-transicao-energetica/",
      from: "The Mobility Factory",
    },
    {
      title:
        "Energia solar, armazenamento, mobilidade eléctrica e blockchain numa só comunidade",
      description:
        "O projecto COMSOLVE está a desenvolver soluções para apoiar a gestão de comunidades de energia renovável (CER) de base solar que integram a vertente de armazenamento de energia, bem como a da mobilidade eléctrica. Esta gestão vai ser facilitada por uma plataforma que recorre a tecnologias como blockchain.",
      image:
        "https://smart-cities.pt/wp-content/uploads/2021/11/0911comunidades-e1674061732473.png",
      url: "https://smart-cities.pt/noticias/1901-comunidade-energia-solar-cer-mobilidade-electrica-armazenamento-blockchain-comsolve/",
      from: "Smart Cities",
    },
    {
      title:
        "Projecto COMSOLVE desenvolve soluções para CER com integração de veículos eléctricos",
      description:
        "Ao modelo de comunidades de energia renovável (CER), o projecto COMSOLVE acrescenta a integração do carregamento de veículos eléctricos e de sistemas de armazenamento de energia. Segundo a Coopérnico, uma das entidades parceiras, o objectivo é estimular um ecossistema de prosumers com “benefícios ambientais, económicos e sociais”.",
      image:
        "https://edificioseenergia.pt/wp-content/uploads/2022/11/shutterstock_2067788045-1280x640.jpg",
      url: "https://edificioseenergia.pt/noticias/1801-projecto-comsolve-cer-integracao-veiculos-electricos/",
      from: "Edifícios e Energia",
    },
    {
      title:
        "Projeto COMSOLVE quer assegurar independência das comunidades de energia",
      description:
        "Num contexto de mercados de eletricidade cada vez mais descentralizados, a participação dos denominados “prosumidores” tem-se relevado essencial. Estes cidadãos, empresas, entidades públicas e outras coletividades, que podem produzir como consumir e armazenar eletricidade, assumem um papel fundamental na constituição  e dinamização das comunidades de energia renovável (CER).",
      image:
        "https://www.ambientemagazine.com/wp-content/uploads/2023/01/Coopernico_2-750x536.jpg",
      url: "https://www.ambientemagazine.com/projeto-comsolve-quer-assegurar-independencia-das-comunidades-de-energia/",
      from: "Ambiente Magazine",
    },
    {
      title:
        "Coopérnico aposta em comunidade de energia renovável com veículos elétricos",
      description:
        "A cooperativa portuguesa de energias renováveis Coopérnico anunciou que vai apostar em 2023 na mobilidade elétrica, ao integrar o projeto COMSOLVE, que visa a criação de uma Comunidade de Energia Renovável (CER), de origem solar fotovoltaica, com integração de veículos elétricos e sistemas de armazenamento de energia baseados em baterias de segunda vida.",
      image:
        "https://cdn4.jornaldenegocios.pt/images/2021-12/img_900x560$2021_12_18_00_59_12_418665.jpg",
      url: "https://www.jornaldenegocios.pt/empresas/energia/detalhe/coopernico-aposta-em-comunidade-de-energia-renovavel-com-veiculos-eletricos",
      from: "Jornal de Negócios",
    },
    {
      title:
        "Coopérnico aposta na mobilidade elétrica com o projeto 'Comsolve'",
      description:
        "A Coopérnico, cooperativa portuguesa de energias renováveis, faz parte do projeto COMSOLVE, que está a criar uma comunidade de energia solar com integração de veículos elétricos, com o objetivo de apostar na mobilidade e criar os chamados “prosumidores”, consumidores que produzem a sua própria energia.",
      image: "https://img.interempresas.net/fotos/3801806.jpeg",
      url: "https://www.oinstalador.com/Artigos/463571-Coopernico-aposta-na-mobilidade-eletrica-com-o-projeto-COMSOLVE.html",
      from: "O Instalador",
    },
    {
      title:
        "Coopérnico aposta na mobilidade com criação de comunidade de energia renovável para veículos elétricos",
      description:
        "Através do projeto COMSOLVE, a Coopérnico vai criar uma comunidade de energia solar com integração de veículos elétricos com o objetivo de apostar na mobilidade e criar os chamados “prosumidores”: os consumidores que produzem a sua própria energia.",
      image:
        "https://www.ambientemagazine.com/wp-content/uploads/2017/01/eletricos-e1550752453893-350x250.jpg",
      url: "https://www.ambientemagazine.com/coopernico-aposta-na-mobilidade-com-criacao-de-comunidade-de-energia-renovavel-para-veiculos-eletricos/",
      from: "Ambiente Magazine",
    },
    {
      title: "Comunidade de energia solar apresentada em Ílhavo",
      description:
        "No próximo dia 24 de setembro, às 10 horas, terá lugar no Mercado Municipal uma sessão pública que visa dar a conhecer o projeto COMSOLVE, esclarecer dúvidas, receber ideias para o desenho de uma Comunidade de Energia Renovável (CER) em Ílhavo e recolher potenciais participantes.",
      image:
        "https://oilhavense.com/wp-content/uploads/2022/03/mercado-de-ilhavo-foto-cmi.jpg",
      url: "https://oilhavense.com/2022/09/12/comunidade-de-energia-solar-apresentada-em-ilhavo/",
      from: "O Ilhavense",
    },
    {
      title: "Ílhavo desafiada para Comunidade de Energia Renovável",
      description:
        "O Mercado Municipal de Ílhavo recebe, este sábado, uma sessão pública do projeto comsolve. Esta sessão servirá para dar a conhecer o projeto aos ilhavenses e esclarecer as suas dúvidas, mas sobretudo receber ideias para o desenho de uma Comunidade de Energia Renovável em Ílhavo e recolher potenciais participantes na mesma.",
      image:
        "https://www.terranova.pt/sites/default/files/styles/node-detail/public/field/image/paineis_solares_1.jpg?itok=2ONJV66C",
      url: "https://www.terranova.pt/noticia/sociedade/ilhavo-desafiada-para-comunidade-de-energia-renovavel",
      from: "Terra Nova",
    },
  ];

  return (
    <>
      <AnimatedPage />
      <section className="main">
        <Container>
          <Row>
            <Col className="text-center">
              <span className="title">News</span>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={10}>
              <div className="news">
                {newsArr.map((item, key) => (
                  <div className="news-item" key={key}>
                    <div className="content">
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noreferrer"
                        className="medium-text title">
                        {item.title}
                      </a>
                      <p className="description">
                        <LinesEllipsis
                          text={item.description}
                          maxLine="2"
                          ellipsis="..."
                          trimRight
                          basedOn="letters"
                        />
                      </p>
                      <span className="from">{item.from}</span>
                    </div>
                    <div className="cover">
                      <img src={item.image} alt={item.title} />
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default News;
