import React from "react";

const partners = [
  {
    id: 1,
    name: "Digitalmente",
    logo: "digitalmente",
    url: "https://digitalmente.net/",
  },
  {
    id: 2,
    name: "Coopérnico",
    logo: "coopernico",
    url: "https://coopernico.org/",
  },
  {
    id: 3,
    name: "Magnum Cap",
    logo: "magnum_cap",
    url: "https://magnumcap.com/pt-pt/",
  },
  {
    id: 4,
    name: "Instituto de Telecomunicações",
    logo: "it",
    url: "https://www.it.pt/",
  },
  {
    id: 5,
    name: "Junta de Freguesia de São Salvador",
    logo: "s_salvador",
    url: "https://freguesiassalvador.pt/",
  },
  {
    id: 6,
    name: "Câmara Municipal de Ílhavo",
    logo: "camara_ilhavo",
    url: "https://www.cm-ilhavo.pt/",
  },
];

function PartnersItems() {
  return (
    <div className="partners-items-loop">
      {partners.map((partnerItem) => (
        <div className="partner" key={partnerItem.id}>
          <a href={partnerItem.url} target="_blank" rel="noreferrer noopener">
            <img
              className="partnerImage"
              src={require(`../assets/partners/${partnerItem.logo}.svg`)}
              alt={partnerItem.name}
              style={{
                height: partnerItem.logo === "camara_ilhavo" ? 100 : "auto",
              }}
            />
          </a>
        </div>
      ))}
    </div>
  );
}

const PartnersComponent = () => {
  return (
    <div className="partners-wrapper">
      <div className="partners-section">
        <div className="partners-items">
          <PartnersItems />
          <PartnersItems />
          <PartnersItems />
        </div>
      </div>
    </div>
  );
};

export default PartnersComponent;
