import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import gsap from "gsap";
import Footer from "./components/footer";
import Header from "./components/header";
import { Route, Routes, useLocation } from "react-router-dom";
import "./styles/App.scss";
import { AnimatePresence } from "framer-motion";

// Pages
import Home from "./pages/home";
import Project from "./pages/project";
import Partners from "./pages/partners";
import Navigation from "./components/navigation";
import News from "./pages/news";

// Routes
const routes = [
  { path: "/", name: "Home", Component: Home },
  { path: "/project", name: "Project", Component: Project },
  { path: "/partners", name: "Partners", Component: Partners },
  { path: "/news", name: "News", Component: News },
];

function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const App = () => {
  /* const app = useRef();
  const scrollContainer = useRef();
  const size = useWindowSize();
  const skewConfigs = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0,
  };

  useEffect(() => {
    setBodyHeight();
  }, [size.height]);

  useEffect(() => {
    requestAnimationFrame(() => skewScrolling());
  }, []);

  const skewScrolling = () => {
    skewConfigs.current = window.scrollY;
    skewConfigs.previous +=
      (skewConfigs.current - skewConfigs.previous) * skewConfigs.ease;
    skewConfigs.rounded = Math.round(skewConfigs.previous * 100) / 100;

    const difference = skewConfigs.current - skewConfigs.rounded;
    const acceleration = difference / size.width;
    const velocity = +acceleration;
    const skew = velocity * 7.5;

    scrollContainer.current.style.transform = `translateY(-${skewConfigs.rounded}px) skewY(${skew}deg)`;

    requestAnimationFrame(() => skewScrolling());
  };

  const setBodyHeight = () => {
    document.body.style.height = `${
      scrollContainer.current.getBoundingClientRect().height
    }px`;
  }; */

  // Prevent flashing
  gsap.to("body", { visibility: "visible" });

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    // Grab inner height of window for mobile reasons when dealing with vh
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 1000);

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  //MAYBE REMOVE
  const location = useLocation();
  useEffect(() => {
    //setBodyHeight();
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }, [location]);

  return (
    <>
      <Navigation key={"navigation"} />
      <Header key={"header"} dimensions={dimensions} />
      {/* <div ref={app} className="App"> */}
      {/* <div ref={scrollContainer} className="scroll" id="scrollDiv"> */}
      <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.pathname}>
          {routes.map(({ path, Component }) => (
            <Route key={path} path={path} element={<Component />}></Route>
          ))}
        </Routes>
        <Footer />
      </AnimatePresence>
      {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default App;
