import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as UpArrow } from "../assets/up-arrow-circle.svg";
import logo from "../assets/logo.svg";
import gsap from "gsap";

let tl = gsap.timeline();

const Header = ({ dimensions }) => {
  const [menuState, setMenuState] = useState({ menuOpened: false });

  const location = useLocation();
  useEffect(() => {
    // runs on location, i.e. route, change
    //console.log("handle route change here", location);
    if (menuState.menuOpened) {
      setMenuState({ menuOpened: false });
    }
  }, [location]);

  useEffect(() => {
    if (menuState.menuOpened === true) {
      // Run open menu animation
      tl.to("nav", { css: { display: "block" } })
        .to("body", {
          css: { overflow: dimensions.width <= 768 ? "hidden" : "auto" },
        })
        .to("nav", {
          duration: 1,
          y: dimensions.width <= 768 ? "100vh" : "70vh",
          ease: "expo.inOut",
        })
        .to(".logo img", {
          /* css: { filter: "brightness(0) invert(1)" }, */
          delay: -1,
          duration: 0.4,
        })
        .to(".hamburger-menu span", {
          delay: -1,
          duration: 0.4,
          scaleX: 0,
          transformOrigin: "50% 0%",
          ease: "expo.inOut",
        })
        .to("#Path_1", {
          duration: 0.4,
          delay: -0.5,
          css: {
            strokeDashoffset: 10,
            strokeDasharray: 5,
          },
        })
        .to("#Path_2", {
          duration: 0.4,
          delay: -0.5,
          css: {
            strokeDashoffset: 10,
            strokeDasharray: 20,
          },
        })
        .to("#Line_1", {
          duration: 0.4,
          delay: -0.5,
          css: {
            strokeDashoffset: 40,
            strokeDasharray: 18,
          },
        })
        .to("#circle", {
          duration: 0.6,
          delay: -0.8,
          css: {
            strokeDashoffset: 0,
          },
        })
        .to(".hamburger-menu-close", {
          duration: 0.6,
          delay: -0.8,
          css: {
            display: "block",
          },
        });
    } else {
      // Run close menu animation
      tl.to("nav", {
        duration: 1,
        y: 0,
        ease: "expo.inOut",
      })
        .to("#circle", {
          duration: 0.6,
          delay: -0.6,
          css: {
            strokeDashoffset: -193,
            strokeDasharray: 227,
          },
        })
        .to("#Path_1", {
          duration: 0.4,
          delay: -0.6,
          css: {
            strokeDashoffset: 10,
            strokeDasharray: 10,
          },
        })
        .to("#Path_2", {
          duration: 0.4,
          delay: -0.6,
          css: {
            strokeDashoffset: 10,
            strokeDasharray: 10,
          },
        })
        .to("#Line_1", {
          duration: 0.4,
          delay: -0.6,
          css: {
            strokeDashoffset: 40,
            strokeDasharray: 40,
          },
        })
        .to(".hamburger-menu span", {
          delay: -0.4,
          duration: 0.4,
          scaleX: 1,
          transformOrigin: "50% 0%",
          ease: "expo.inOut",
        })
        .to(".hamburger-menu-close", {
          css: {
            display: "none",
          },
        })
        .to("body", {
          css: {
            overflow: "auto",
          },
        })
        .to("nav", {
          css: {
            display: "none",
          },
        });
    }
  }, [dimensions, menuState.menuOpened]);

  return (
    <div className="header">
      <Container>
        <Row className="v-center space-between">
          <Col>
            <div className="logo">
              <NavLink to="/">
                <img src={logo} width={150} alt="COMSOLVE" />
              </NavLink>
            </div>
          </Col>
          <Col className="d-flex justify-content-end">
            <div className="nav-toggle">
              <div
                className="hamburger-menu"
                onClick={() => setMenuState({ menuOpened: true })}>
                <span></span>
                <span></span>
              </div>
              <div
                className="hamburger-menu-close"
                onClick={() => setMenuState({ menuOpened: false })}>
                <UpArrow />
              </div>
            </div>

            <div className="nav-menu-links">
              <NavLink
                to="/project"
                className={(navData) => (navData.isActive ? "active" : "")}>
                Project
              </NavLink>
              <NavLink
                to="/partners"
                className={(navData) => (navData.isActive ? "active" : "")}>
                Partners
              </NavLink>
              <NavLink
                to="/news"
                className={(navData) => (navData.isActive ? "active" : "")}>
                News
              </NavLink>
              <a
                href="https://app.comsolve.pt/"
                target="_blank"
                rel="noreferrer">
                Platform
              </a>
              <a
                href="https://api.comsolve.pt/external/docs"
                target="_blank"
                rel="noreferrer">
                Documentation
              </a>
              <a
                href="https://forms.gle/hTV7mrshwMn8ZgTd7"
                target="_blank"
                rel="noreferrer">
                Apply to Join
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Header;
