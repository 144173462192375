import React from "react";
import { Container, Row } from "react-bootstrap";
import heroImage from "../assets/herobg.jpg";

const Banner = () => {
  return (
    <section className="main-banner">
      <div className="banner-title">
        <Container>
          <Row>
            <h2>
              <div className="line">
                <span>COMunidade de energia SOLar com</span>
              </div>
              <div className="line">
                <span>integração de Veículos Elétricos</span>
              </div>
            </h2>
          </Row>
        </Container>
      </div>
      <div className="banner-image">
        <img className="hero-media" src={heroImage} alt="" />
      </div>
      {/* <span className="scroll-fixed-text">Scroll ―</span> */}
    </section>
  );
};

export default Banner;
