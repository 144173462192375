import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AnimatedPage from "../components/animatedPage";

const Project = () => {
  return (
    <>
      <AnimatedPage />
      <section className="main">
        <Container>
          <Row>
            <Col className="text-center">
              <span className="title">The Project</span>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={10}>
              <div className="features">
                <div className="feature-item">
                  <p className="medium-text">Name</p>
                  <p className="description">
                    COMSOLVE — COMunidade de energia SOLar com integração de
                    Veículos Elétricos
                  </p>
                </div>
                <div className="feature-item">
                  <p className="medium-text">Reference</p>
                  <p className="description">
                    CENTRO/LISBOA-01-0247-FEDER-047019
                  </p>
                </div>
                <div className="feature-item">
                  <p className="medium-text">Project Leader</p>
                  <p className="description">
                    DIGITALMENTE, Novas Tecnologias de Comunicação Lda
                  </p>
                </div>
                <div className="feature-item">
                  <p className="medium-text">Dates</p>
                  <p className="description">
                    Approval date: 07-06-2021 <br />
                    Start date: 15-06-2021 <br />
                    End date: 30-06-2023
                  </p>
                </div>
                <div className="feature-item">
                  <p className="medium-text">Eligible Costs</p>
                  <p className="description">819 350,93 € (Total)</p>
                </div>
                <div className="feature-item">
                  <p className="medium-text">Funding</p>
                  <p className="description">
                    FEDER - CENTRO 2020: 545 466,17 € (Total)
                    <br />
                    FEDER - LISBOA 2020: 38 436,56 € (Total)
                  </p>
                </div>
              </div>
              <div>
                <p>
                  The COMSOLVE project aims to develop management solutions for
                  renewable energy communities (CER) with the integration of
                  electric vehicles and energy storage systems based on second
                  life batteries.{" "}
                </p>
                <p>
                  The project will include the decentralized production of
                  electricity from photovoltaic panels and the sharing of energy
                  produced by members of the community, allowing the development
                  of the energy markets paradigm centered on the role of
                  "prosumers" - citizens and entities simultaneously producers
                  and consumers of electricity .
                </p>
                <p>
                  In this community ecosystem, members will be able to carry out
                  energy purchase and sale transactions directly with each
                  other, in a secure and distributed manner. These transactions,
                  based on blockchain technologies and peer-to-peer (P2P)
                  trading models, will be based on the consumption profiles and
                  preferences (e.g., purchase / sale price and energy source) of
                  the various users.
                </p>
                <p>
                  The management entity will also have the role of optimizing
                  the community's energy balance, evaluating in real time the
                  purchase and sale price of electricity to the distribution
                  network and making decisions on storage, purchase of energy
                  for future consumption or sale of the surplus produced.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Project;
